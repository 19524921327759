<template>
    <div class="vx-col md:w-full sm:w-auto">
        <div class="vx-row md:w-full">
            <vx-tooltip class="position-icon" text="Cấp phát" position="top">
                <feather-icon icon="UserIcon" class="action-success icon-btn"
                              svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
                              @click="assignDevice"/>
            </vx-tooltip>

            <vx-tooltip class="position-icon" text="Thu hồi" position="top">
                <feather-icon icon="UserXIcon" class="action-success icon-btn"
                              svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
                              @click="confirmRevoke"/>
            </vx-tooltip>

            <vx-tooltip class="position-icon" text="Chỉnh sửa" position="top">
                <feather-icon icon="EditIcon" class="action-success icon-btn"
                              svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
                              @click="editRecord"/>
            </vx-tooltip>

            <vx-tooltip class="position-icon" text="Xóa" position="top">
                <feather-icon icon="TrashIcon" class="action-err icon-btn"
                              svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                              @click="confirmDeleteRecord"/>
            </vx-tooltip>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CellRendererActions',
    methods: {
        assignDevice() {
            // if (this.params.data.employeeId) {
            //   return this.$vs.dialog({
            //     color: 'danger',
            //     title: `Lỗi`,
            //     text: `Thiết bị đã cấp phát. Xin thu hồi trước khi cấp phát lại`,
            //     acceptText: "Đồng ý"
            //   })
            // }
            this.$router.push(`/user/assign-device?branch_id=${this.params.data.organizationBranchId}&device_id=${this.params.data.deviceId}&id=${this.params.data.id || ""}`).catch(() => {
            })
        },
        editRecord() {
            this.$router.push(`/user/edit-device?branch_id=${this.params.data.organizationBranchId}&id=${this.params.data.deviceId}`).catch(() => {
            })
        },
        confirmDeleteRecord() {

            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận xoá`,
                text: `Bạn có chắc là muốn xóa thiết bị "${this.params.data.displayDevice}" không ?`,
                accept: this.deleteRecord,
                acceptText: "Xoá",
                cancelText: "Huỷ"
            })
        },
        deleteRecord() {
            this.$vs.loading();
            this.$crm.post('/device/delete/' + this.params.data.deviceId).then(() => {
                this.$vs.loading.close();
                this.showDeleteSuccess();
                this.$store.commit('UPDATE_DEVICE', true);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        showDeleteSuccess() {
            this.$vs.notify({
                color: 'success',
                text: 'Xoá thành công',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        },
        confirmRevoke() {
            if (!this.params.data.employeeId) {
                return this.$vs.dialog({
                    color: 'danger',
                    title: `Lỗi`,
                    text: `Không thể thu hồi thiết bị chưa cấp phát`,
                    acceptText: "Đồng ý"
                })
            }
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận thu hồi`,
                text: `Bạn có chắc là muốn thu hồi "${this.params.data.displayDevice}" của "${this.params.data.displayEmployee}" không ?`,
                accept: this.revokeDevice,
                acceptText: "Xác nhận",
                cancelText: "Huỷ"
            })
        },
        revokeDevice() {
            this.$vs.loading();
            this.$crm.post('/device/revoke/' + this.params.data.id).then(() => {
                this.$vs.loading.close();
                this.showRevokeSuccess();
                this.$store.commit('UPDATE_DEVICE', true);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        showRevokeSuccess() {
            this.$vs.notify({
                color: 'success',
                text: 'Thu hồi thành công',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        }
    }
}
</script>
<style scoped>

</style>
